import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import RechargeRecords from '../pages/mine/RechargeRecords'/* 充值记录 */
import RechargeInfo from '../pages/mine/RechargeInfo'


Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: 'หน้าแรก' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: 'หน้าแรก' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: 'เลือกนางสนม' } },
    { path: '/List', name: 'list', component: List, meta: { title: 'รายการคัดเลือกนางสนม' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: 'รายละเอียดการเลือกตั้งนางสนม' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: 'ของฉัน' } },
    { path: '/Video', name: 'video', component: Video, meta: { title: 'วิดีโอ' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: 'เกม' } },
    { path: '/Login', name: 'login', component: Login, meta: { title: 'เข้าสู่ระบบ' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: 'ลงทะเบียน' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: 'บริการออนไลน์' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: 'บริการออนไลน์' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: 'ติดตั้ง' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: 'ข้อมูลพื้นฐาน' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: 'ปรับเปลี่ยนชื่อจริง' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: 'แก้ไขเพศ' } },
    { path: '/Language', name: 'Language', component: Language, meta: { title: 'การเลือกภาษา' } },
    { path: '/Recharge', name: 'Recharge', component: Recharge, meta: { title: 'เติมเงิน' } },
    { path: '/RechargeInfo', name: 'RechargeInfo', component: RechargeInfo, meta: { title: 'เติมเงิน' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: 'การจัดการรหัสผ่านการชำระเงิน' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: 'การจัดการรหัสผ่านเข้าสู่ระบบ' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: 'หน้ารายละเอียด' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: 'ประกาศ' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: 'การเล่นวิดีโอ' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: 'กรอกบัตรชำระเงิน' } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: 'กรอกบัตรชำระเงิน' } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: 'ถอน' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: 'บันทึกย่อ' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: 'บันทึกการถอนเงิน' } },
    { path: '/RechargeRecords', name: 'RechargeRecord', component: RechargeRecords, meta: { title: 'บันทึกการเติมเงิน' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: 'บันทึกงาน' } },


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router